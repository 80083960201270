import React, { useState, useContext } from 'react';

import ReactLoading from 'react-loading';
import classNames from 'classnames';

import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useValidEmail, useValidPassword, useValidUsername } from '../../hooks/useAuthHooks';
import { Email, Password, Username } from '../../components/AuthComponents';
import { AuthWrapperRegister, AuthWrapperWithLogo } from '../../components/AuthWrapper';

import { AuthContext } from '../../contexts/authContext';
import { useLazyQuery } from '@apollo/client';
import { PENDING_INVITATIONS_QUERY } from '../OrganizationPage/queries';

const SignUp: React.FunctionComponent<{}> = () => {
  const { search } = window.location;
  const inviteEmail = new URLSearchParams(search).get('invite');

  inviteEmail && localStorage.setItem("Ten99PolicyDefaultEmail", inviteEmail);

  const { email, setEmail, emailIsValid } = useValidEmail(inviteEmail || '');
  const { password, setPassword, passwordIsValid } = useValidPassword('');
  const { username, setUsername, usernameIsValid } = useValidUsername(inviteEmail || '');
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [created, setCreated] = useState(false);

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('');

  const isInvalid =
    !usernameIsValid ||
    username.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const [checkInvitation] = useLazyQuery(PENDING_INVITATIONS_QUERY, {
    variables: { "email": username },
  });

  const signInClicked = async () => {
    setSubmitting(true);
    setError('');

    const { data } = await checkInvitation();

    if (!data) {
      setError('An error occurred. Please try again.');
      setSubmitting(false);
      return;
    }

    if (data && data.pendingInvitations.length === 0) {
      setError('Your email is not among those invited to register.');
      setSubmitting(false);
      return;
    }

    try {
      await authContext.signUpWithEmail(username, username, password);
      setCreated(true);
      setSubmitting(false);
    } catch (err) {
      if (err instanceof Error && "message" in err) {
        setError(err.message);
      }
      setSubmitting(false);
    }
  };

  const SignUpButton = (props) => {
    if (props.submitting) {
      return (
        <button className="button-reset bg-animate white mt0 bn ttu w-100 pa3 hover-white">
          <ReactLoading type="spin" color="#cccccc" height={20} width={20} className="center" />
        </button>
      );
    } else {
      return (
        <input
          className={classNames(
            'pointer button-reset bg-animate white mt0 bn w-100 pa3 hover-white br2',
            { 'bg-brand-pink': !props.isInvalid },
            { 'bg-gray': props.isInvalid }
          )}
          value="Create account"
          disabled={props.isInvalid}
          onClick={signInClicked}
          type="submit"
        />
      );
    }
  };

  const signUp = (
    <AuthWrapperRegister>
      <Helmet>
        <meta
          name="description"
          content="Create a free account to test integrating our API for on-demand workers comp and liability insurance quotes with your digital workforce platform."
        />
        <title>Register – 1099Policy</title>
      </Helmet>
      <fieldset id="sign_up" className="ba b--transparent ph0 mh0 dark-gray">
        <Email email={email} emailIsValid={emailIsValid} setEmail={setEmail} hideEmail={true} />
        <Username
          username={username}
          usernameIsValid={usernameIsValid}
          setUsername={setUsername}
        />
        <Password
          label="Password"
          placeholder="8+ characters (upper, lower, number, and special)"
          passwordIsValid={passwordIsValid}
          setPassword={setPassword}
        />
        <Password label="Confirm Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} />
        <div className="brand-pink pb3 f6">{error}</div>
      </fieldset>

      <SignUpButton isInvalid={isInvalid} submitting={submitting} />

      <p className="black-40 pt3 pointer f6" onClick={() => navigate('/signin')}>
        Already have an account? <span className="fw5 brand-pink no-underline">Sign in</span>
      </p>
    </AuthWrapperRegister>
  );

  const accountCreated = (
    <AuthWrapperWithLogo>
      <Helmet>
        <meta
          name="description"
          content="Create a free account to test integrating our API for on-demand workers comp and liability insurance quotes with your digital workforce platform."
        />
        <title>Complete Registration | 1099Policy</title>
      </Helmet>
      <div className="pt4">
        <p className="f6 gray lh-copy">
          Created an account for <b>{username}</b>. Check the email address you provided and verify
          your account by clicking the link in the email.
        </p>
      </div>
      <div className="db tc mv4">
        <input
          className="pointer button-reset white mt0 bn w-100 pa3 bg-brand-pink hover-white br2"
          value="Continue"
          onClick={() => navigate('/complete-registration')}
          type="submit"
        />
      </div>
    </AuthWrapperWithLogo>
  );

  return <div>{!created ? signUp : accountCreated}</div>;
};

export default SignUp;
