import React, {useState} from 'react'
import ReactTooltip from 'react-tooltip'
import { Field } from 'formik';
import classNames from 'classnames'

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { getStyles} from './formValidation';

import AutoSizingInputAdvanced from '../../components/AutoSizingInputAdvanced';

import { states } from '../../constants/states';

function ContractorSideNav(props){
  let [idCopied, setIdCopied] = useState()
  
  const {
    errors,
    touched,
    isActiveEdit,
    data
  } = props
  
  const withholdPremiumOrgOverride = data?.withholdPremiumOrgOverride
  const withholdPremium = data?.withholdPremium
  
  const withholdPremiumMsg = withholdPremium || withholdPremiumOrgOverride ? 'True' : 'False'
  
  function afterHideTip() {
    setIdCopied(false)
  }

  return (
    <header className="fn fl-ns w-100-ns mb3">
      <table className="w-100" cellSpacing="0">
        <tbody>
        <tr>
          <td className="pr4">
            <div className="fl pb2 pr2">
              <Field name="firstName"
                style={getStyles(errors, 'firstName', touched)}
                placeholder="First name"
                font="24px Roboto"
                fontWeight="600"
                padding={10}
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 f3 fw6 pv2", 
                {"ba b--black-10 br2": isActiveEdit},
                {"ba b--transparent": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>
            </div>
            <div className="fl pb2">
              <Field name="lastName" 
                style={getStyles(errors, 'lastName', touched)}
                placeholder="Last name"
                font="24px Roboto"
                fontWeight="600"
                padding={10}
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 f3 fw6 pv2",
                {"ba b--black-10 br2": isActiveEdit},
                {"ba b--transparent": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>
            </div>
            <div className="fw2 f6 gray">
              <Field name="email" 
                style={getStyles(errors, 'email', touched)}
                placeholder="Email"
                font="14px Roboto"
                fontWeight="300"
                minwidth={240}
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 f6 fw3", 
                {"ba b--black-10 br2 pv2": isActiveEdit},
                {"ba b--transparent": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <table cellSpacing="0">
        <tbody className="lh-copy f6 fw2 gray">
          <tr>
            <td className="pb3 pr4">
            {
                (isActiveEdit || (!isActiveEdit && data?.address?.line1)) && 

                <div className="mt1">
                  <Field name="line1" 
                    placeholder="Line 1"
                    font="14px Roboto"
                    fontWeight="300"
                    minwidth={240}
                    component={AutoSizingInputAdvanced}
                    className={classNames("outline-0 f6 fw3", 
                    {"ba b--black-10 br2 pv2": isActiveEdit},
                    {"ba b--transparent": !isActiveEdit})}
                    type="text" 
                    autoComplete="off"
                    readOnly={!isActiveEdit}/>
                </div>
            }

            {
                (isActiveEdit || (!isActiveEdit && data?.address?.line2)) &&
                
                <div className="mt1">
                  <Field name="line2" 
                    placeholder="Line 2"
                    font="14px Roboto"
                    fontWeight="300"
                    minwidth={240}
                    component={AutoSizingInputAdvanced}
                    className={classNames("outline-0 f6 fw3", 
                    {"ba b--black-10 br2 pv2": isActiveEdit},
                    {"ba b--transparent": !isActiveEdit})}
                    type="text" 
                    autoComplete="off"
                    readOnly={!isActiveEdit}/>
                </div>
            }

            {
                (isActiveEdit || (!isActiveEdit && data?.address?.line3)) &&

                <div className="mv1">
                  <Field name="line3" 
                    placeholder="Line 3"
                    font="14px Roboto"
                    fontWeight="300"
                    minwidth={240}
                    component={AutoSizingInputAdvanced}
                    className={classNames("outline-0 f6 fw3", 
                    {"ba b--black-10 br2 pv2": isActiveEdit},
                    {"ba b--transparent": !isActiveEdit})}
                    type="text" 
                    autoComplete="off"
                    readOnly={!isActiveEdit}/>
                </div>
            }
            
            <div>
              <Field name="locality" 
                placeholder="City"
                font="14px Roboto"
                fontWeight="300"
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 dib f6 fw3",
                {"ba b--black-10 br2 pv2": isActiveEdit},
                {"ba b--transparent": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>

              {
                isActiveEdit ?
                <Field name="region"
                  component="select" 
                  className={classNames("outline-0 dib f6 fw3 ml2", 
                  {"ba b--black-10 br2 pv2": isActiveEdit},
                  {"ba b--transparent": !isActiveEdit})}
                  readOnly={!isActiveEdit}
                >
                  {states.map(state => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.abbreviation}
                    </option>
                  ))}
                </Field> : <div className="dib black">{data?.address?.region}</div>
              }

              <Field name="postalcode" 
                placeholder="Zip code"
                font="14px Roboto"
                fontWeight="300"
                minwidth={70}
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 dib f6 fw3  ml2", 
                {"ba b--black-10 br2  pa2": isActiveEdit},
                {"ba b--transparent": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>
            </div>

            </td>
          </tr>
        </tbody>
      </table>
      <div className="fw2 f6 gray mv3">
        <div className="pa1">Agency Remit: 
          {
            isActiveEdit ?
              <Field name="withholdPremium" 
              component="select" 
              className={classNames("outline-0 ml1 dib f6 fw3", 
              {"ba b--black-10 br2 pv2": isActiveEdit},
              {"ba b--transparent": !isActiveEdit})}
              disabled={withholdPremiumOrgOverride }>
                <option value="true">True</option>
                <option value="false">False</option>
              </Field> : <div className="pl1 dib gary">{withholdPremiumMsg}</div>
          }

          { withholdPremiumOrgOverride && 
            <span className="f7 pt2 black-40 db">
              Org-level agency remit is set to "True"
            </span> 
          }
        </div>
        <div>
          {
            (isActiveEdit || (!isActiveEdit && data?.poContactName)) && 

            <div className="mt1">
              <Field name="poContactName" 
                placeholder="PO Contact Name"
                font="12px Roboto"
                fontWeight="300"
                minwidth={240}
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 f6 fw3", 
                {"ba b--black-10 br2 pv2": isActiveEdit},
                {"ba b--transparent black-40": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>
            </div>
            }

            {
            (isActiveEdit || (!isActiveEdit && data?.poContactEmail)) && 

            <div className="mt1">
              <Field name="poContactEmail" 
                placeholder="PO Contact Email"
                font="12px Roboto"
                fontWeight="300"
                minwidth={240}
                component={AutoSizingInputAdvanced}
                className={classNames("outline-0 f6 fw3", 
                {"ba b--black-10 br2 pv2": isActiveEdit},
                {"ba b--transparent black-40": !isActiveEdit})}
                type="text" 
                autoComplete="off"
                readOnly={!isActiveEdit}/>
            </div>
            }
        </div>
        
        
      </div>
      <CopyToClipboard text={data.publicId} onCopy={() => setIdCopied(true)}>
        <div data-tip data-for='contractor_id_copy' className="mv3 fw2 f6 gray ba br2 lh-copy dib pv1 ph2 b--black-10 pointer">
          {data.publicId}
        </div>
      </CopyToClipboard>
      <ReactTooltip 
        id='contractor_id_copy' 
        place='bottom'
        type='info'
        afterHide={afterHideTip} 
        effect='solid'>
        <span>{idCopied ? 'Copied!' : 'Click to copy'}</span>
      </ReactTooltip>
    </header>
  )
}

export default ContractorSideNav