import React from "react";
import Modal from "react-modal";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import dateFnsFormat from "date-fns/format";
import { useMutation } from "@apollo/client";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import PageHeader from "../../components/PageHeader";
import { formatDate, parseDate } from "../../utils/date";

import { UPDATE_ASSIGNMENT_DETAILS } from "./mutations";

const FORMAT = "MM/dd/yyyy";

let contentStyle = {
  width: "40%",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.2)",
};

let modalStyles = {
  content: contentStyle,
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const validationSchema = Yup.object().shape({
  jobName: Yup.string(),
  entityName: Yup.string(),
  clientName: Yup.string(),
  effectiveDate: Yup.date(),
  endDate: Yup.date().min(Yup.ref("effectiveDate"), "End date must be after effective date"),
});

function UpdateAssignmentModal({
  modalIsOpen,
  closeModal,
  assignmentChangeModalValues,
  setSuccessMessage,
  setErrorMessage,
}) {
  
  const node = assignmentChangeModalValues?.node
  const quoteId = node?.__typename === "Quote" ? node.publicId : null;
  const assignmentId = node?.__typename === "Assignment" ? node.publicId : null;

  const [updateAssignmentDetails, { loading, error, reset: updateDetailsReset }] = useMutation(
    UPDATE_ASSIGNMENT_DETAILS,
    {
      onCompleted: (data) => {
        if (data.updateAssignmentDetails.ok) {
          setSuccessMessage("Assignment updated successfully.");
          closeModal();
          updateDetailsReset();
        } else {
          setErrorMessage(data.updateAssignmentDetails.message);
          updateDetailsReset();
        }
      },
      onError: (error) => {
        setErrorMessage(error.message)
        updateDetailsReset();
      },
      refetchQueries: ["Contractor", "ContractorByID"],
    }
  );

  const initialValues = {
    jobName: node?.job?.name || "",
    clientName: node?.job?.entity?.name || "",
    effectiveDate: new Date(node?.effectiveDate),
    endDate: new Date(node?.endDate),
  };

  const handleSubmit = (values) => {
    updateAssignmentDetails({
      variables: {
        quoteId: quoteId,
        assignmentId: assignmentId,
        jobId: node?.job?.publicId,
        jobName: values.jobName,
        clientName: values.clientName,
        effectiveDate: values.effectiveDate.toISOString().split("T")[0],
        endDate: values.endDate.toISOString().split("T")[0],
      },
    });
  };

  const renderError = (errorMsg) => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red">
      <span className="lh-title">
        {" "}
        {errorMsg ??
          "There was an error updating the assignment details. Please try again."}
      </span>
    </div>
  );

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Update Assignment Details"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, setFieldValue, submitForm }) => (
          <Form>
            <div 
              className="roboto flex flex-column"
              style={{
                height: "80vh",
                maxHeight: "900px",
                minHeight: "520px",
                overflow: "auto",
              }}
            >
              <div className="flex-grow-1 overflow-auto">
                <PageHeader title={"Update Assignment Details"} />
                {error && renderError(error.message)}
                <div className="flex flex-column mt3">
                  <div className="mb3">
                    <label className="f6 fw5 db mb2" htmlFor="jobName">
                      Job Assignment Name
                    </label>
                    <Field
                      name="jobName"
                      className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                      type="text"
                    />
                    {errors.jobName && touched.jobName && (
                      <div className="red">{errors.jobName}</div>
                    )}
                  </div>

                  <div className="mb3">
                    <label className="f6 fw5 db mb2" htmlFor="clientName">
                      Client Name
                    </label>
                    <Field
                      name="clientName"
                      className="outline-0 pa2 w-100 f6 fw3 ba b--black-10 br2"
                      type="text"
                    />
                    {errors.clientName && touched.clientName && (
                      <div className="red">{errors.clientName}</div>
                    )}
                  </div>

                  <div className="flex w-100">
                    <div className="mr2">
                      <label className="f6 fw5 db mb2 mt3" htmlFor="effectiveDate">
                        Effective Date
                      </label>
                      <DayPickerInput
                        value={values.effectiveDate}
                        onDayChange={(day) => setFieldValue("effectiveDate", day)}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format={FORMAT}
                        placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
                        inputProps={{
                          className: "f6 fw3"
                        }}
                      />
                      {errors.effectiveDate && touched.effectiveDate && (
                        <div className="red">{errors.effectiveDate}</div>
                      )}
                    </div>

                    <div>
                      <label className="f6 fw5 db mb2 mt3" htmlFor="endDate">
                        End Date
                      </label>
                      <DayPickerInput
                        value={values.endDate}
                        onDayChange={(day) => setFieldValue("endDate", day)}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format={FORMAT}
                        placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
                        inputProps={{
                          className: "f6 fw3"
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-column pa3 bt b--light-gray">
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="f6 link dim br2 ba ph3 pv2 dib black pointer mr2"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="f6 link dim br2 ph3 pv2 dib bn pointer white bg-blue hover-bg-blue-80"
                    disabled={loading}
                    onClick={submitForm}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default UpdateAssignmentModal;
