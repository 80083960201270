import React, { useState } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";

const PopoverMenu = (props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { children, menuContent, onClose, positions } = props;

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={positions || ["left"]}
      padding={5}
      onClickOutside={() => {
        setIsPopoverOpen(false);
        onClose && onClose();
      }}
      // ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"#fff"}
          arrowSize={8}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div
            className="pv0 pl3 pr4 bg-white roboto shadow-30 br2"
            onClick={(e) => {
              e.stopPropagation();
              if (!e.target.getAttribute("data-open")) {
                setIsPopoverOpen(!isPopoverOpen);
              }
            }}
          >
            {menuContent()}
          </div>
        </ArrowContainer>
      )}
    >
      <span onClick={() => setIsPopoverOpen(!isPopoverOpen)}>{children}</span>
    </Popover>
  );
};

export default PopoverMenu;
