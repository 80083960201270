import React from 'react'

import ReactLoading from 'react-loading'

function LoadingSpinner(){
  return (
    <ReactLoading type={'spin'} color={'#cccccc'} width={14} height={14} />
  )
}

export default LoadingSpinner