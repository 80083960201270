import { gql } from '@apollo/client'

const BILLING_QUERY = gql`
  query Billing($cursor: String) {
    billing {
      billingModel
      agencyPayInvoices(first:25, after: $cursor, sort:CREATED_DESC) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            date
            dueDate
            campaignName
            amountDue
            purchaseOrderId
            protectedUrl
            status
          }
        }
      }
    }
  }
`

export { BILLING_QUERY }