import React from 'react';
import ReactLoading from 'react-loading';

import classNames from 'classnames'

import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { useContext } from 'react';
import { AuthContext } from '../../contexts/authContext'; // Adjust the import path as necessary

import MainWrapper from '../../components/MainWrapper';
import { strToCurrencyFormatter } from '../../utils/currency';
import withAuthorization from '../../utils/withAuthorization';

import { BILLING_QUERY } from './queries';

import { environments } from "../../constants/environments";

const BILLING_MODEL_MAPPING = {
  "purchase_order_billing": "Purchase Order",
  "net_terms_billing": "Net Term Billing",
}

const HEADER_BY_BILLING_MODEL = {
  "purchase_order_billing": "CAMPAIGN (PURCHASE ORDER #)",
  "net_terms_billing": "DESCRIPTION",
}

function DownloadIcon({ color }) {
  return (
    <svg
      style={{ height: 20, verticalAlign: "bottom", fill: color }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M21 15.6v3.6a1.8 1.8 0 01-1.8 1.8H4.8A1.8 1.8 0 013 19.2v-3.6h1.8v3.6h14.4v-3.6H21zm-8.1-3.073l2.964-2.963 1.272 1.272L12 15.973l-5.136-5.137 1.272-1.272 2.964 2.963V3h1.8v9.527z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function Badge(props){
  return (
    <span
      className={classNames(
        "f7 fw6 link br2 ph2 pv1 dib",
        {
          "blue bg-washed-blue": props?.status?.toLowerCase() === "processing",
        },
        {
          "green bg-washed-green":
            props?.status?.toLowerCase() === "paid" ||
            props.color === "green",
        },
        {
          "red bg-washed-red":
            props?.status?.toLowerCase() === "overdue" ||
            props.color === "red",
        },
        {
          "silver bg-light-gray":
            props?.status?.toLowerCase() === "sent" ||
            props.color === "silver",
        }
      )}
    >
      {props.status || props.children}
    </span>
  )

}

function InvoicesTable(props){
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const cursor = searchParams.get('starting_after')

  const { loading, data, fetchMore } = useQuery(BILLING_QUERY, {
    variables: {cursor},
    notifyOnNetworkStatusChange: true
  })

  // Add this function to handle the URL with auth headers
  const { sessionInfo } = useContext(AuthContext);

  async function getProtectedUrlWithAuth(url) {
    const token = sessionInfo.idToken;
    const environment = localStorage.getItem('Ten99PolicyEnvironment') || environments.PRODUCTION;
    const organization = localStorage.getItem("Ten99PolicyOrganization") || "";

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Ten99Policy-Environment': environment,
          'Ten99Policy-Organization': organization,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch signed URL');
      }

      const data = await response.json();
      return data.signed_url;
    } catch (error) {
      console.error('Error fetching protected URL:', error);
      return null; // or handle the error as needed
    }
  }


  const renderHeader = (data) => {
    if (!data) return null

    const billing_method = BILLING_MODEL_MAPPING[data.billing?.billingModel]
    return (
      <div className="relative pb3">
        <div className="fl">
          <b>Billing method:</b> {billing_method}
        </div>
      </div>
    )}

  const renderPagination = (pageInfo) => {
    const hasNextPage = pageInfo?.hasNextPage
    
    return(
      <div className="tr dtc">
        <div className="dib">
          <button 
          type="button" 
          onClick={() => fetchNextPage(data)}
          disabled={!hasNextPage}
          className={classNames("fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml2 f7 lh-title border-box dib",
            {"bg-animate hover-bg-light-gray": hasNextPage})}>
            Next
          </button>
          <button 
          type="button" 
          onClick={() => navigate(-1)}
          disabled={cursor == null}
          className={classNames("fr outline-0 pointer br2 ba b--black-20 bg-white pa2 ml1 f7 lh-title border-box dib", 
            {"bg-animate hover-bg-light-gray": cursor != null})}>
            Previous
          </button>
        </div>
      </div>
    )
  }

  const renderTotalResults = (totalCount) => {
    return( 
      totalCount ? 
        <div className="tl dt">
          <div className="dib">
            <div className="f6"><b>{totalCount}</b> results</div>
          </div>
        </div> : null
    )
  }

  const fetchNextPage = (data) => {
    const cursor = data.billing?.agencyPayInvoices?.pageInfo.endCursor
    fetchMore({
      variables: {
        cursor: cursor
      },
    })
    navigate(`/organization/billing?starting_after=${cursor}`)
  }

  const renderRows = (data) => {
    const rows = data.billing?.agencyPayInvoices?.edges?.map((row) => {
      const node = row.node
      
      return(
        <tr key={node.id} className="bb b--light-gray">
          <td className="pv3 ph2 f6">PO# Invoice</td>
          <td className="pv3 ph2 f6 w-30">{node.campaignName} ({node.purchaseOrderId})</td>
          <td className="pv3 ph2 f6 tc">{strToCurrencyFormatter.format(node.amountDue/100)}</td>
          <td className="pv3 ph2 f6 w-10-l">{node.date}</td>
          <td className="pv3 ph2 f6 tc w1-10">{node.dueDate}</td>
          <td className="pv3 ph2 f6 tc">
            <Badge status={node.status} />
          </td>
          <td className="pv3 ph2 f6 tc">
            <button 
              className="f6 link dim br2 ph1 pv1 dib white bn pointer"
              onClick={async () => {
                const signedUrl = await getProtectedUrlWithAuth(node.protectedUrl);
                if (signedUrl) {
                  window.open(signedUrl, '_blank');
                } else {
                  console.error('Failed to retrieve signed URL');
                  // Optionally, show an error message to the user
                }
              }}
            >
              <DownloadIcon color="#000" />
            </button>
          </td>
        </tr>
      )
    })
    
    return rows
  }

  function TableShell(props){
    if (!props.data) return null
    
    const header = HEADER_BY_BILLING_MODEL[props.data?.billing?.billingModel]
    return(
      <div>
        <table className="w-100 ba b--light-gray collapse ph2 mt4 mb3">
          <thead>
            <tr className="bb b--light-gray bg-black-05">
              <th className="tl pv3 ph2 fw5 f7 ttu w-10">Type</th>
              <th className="tl pv3 ph2 fw5 f7 ttu">{header}</th>
              <th className="tl pv3 ph2 fw5 f7 ttu tc">Amount</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-10-l">Sent Date</th>
              <th className="tl pv3 ph2 fw5 f7 ttu tc">Due Date</th>
              <th className="tl pv3 ph2 fw5 f7 ttu tc">Status</th>
              <th className="tl pv3 ph2 fw5 f7 ttu tc"></th>
            </tr>
          </thead>
          <tbody>
            {props.children}
          </tbody>
        </table>
        <div className="db dt w-100 v-mid">
          {props.totalCount ? renderTotalResults(props.totalCount) : null}
          {props.pageInfo ? renderPagination(props.pageInfo) : null}
        </div>
      </div>
    )
  }

  const loadingRow = () => (
    <tr><td colSpan={7}><ReactLoading type={'spin'} color={'#cccccc'} className="mv4 center" /></td></tr> 
  )

  const renderLoadingTable = () => {
    return(
      <TableShell data={data}>
        {loadingRow()}
      </TableShell>
    )
  }

  const renderTable = () => {
    if (data.billing?.agencyPayInvoices?.totalCount < 1) {
      return (
        <TableShell data={data}>
          <tr><td colSpan={7}>
            <div className="tc pa3">No invoices found</div>
          </td></tr> 
        </TableShell>
      )
    }

    const pageInfo = data.billing?.agencyPayInvoices?.pageInfo
    const totalCount = data.billing?.agencyPayInvoices?.totalCount
    
    return (
      <TableShell data={data} pageInfo={pageInfo} totalCount={totalCount}>
        {renderRows(data)}
      </TableShell>
    )
  }

  let table;
  
  if(loading || !data){
    table = renderLoadingTable()
  } else {
    table = renderTable()
  }
  
  return (
    <div>
      {renderHeader(data)}
      {table}
    </div>
  
  )
}

function BaseInvoices(props){
  return (
    <MainWrapper isTestView={props.isTestView}>
    <header className="mb3">
      <h2 className="fw3 dark-gray mt0 mb4">Billing</h2>
    </header>
    <InvoicesTable />
    </MainWrapper>
  )
}

export const Invoices = withAuthorization(BaseInvoices, ['admin', 'owner']);