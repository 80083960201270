import React, { useState } from "react"

import classNames from "classnames"

const APPROVED = 'APPROVED'
const DENIED = 'DENIED'


function CertificatesTable(props){
  const {filteredCertificates} = props

  function getCertificateReviewCoverageDetails(data){
    if (!data?.coverages) {
      return []
    }

    const { commercial_general_liability, workers_compensation } = data.coverages;
    let result = [];

    if (commercial_general_liability && commercial_general_liability.policy_number_string) {
      const glStartDate = commercial_general_liability.policy_effective_date
      const glEndDate = commercial_general_liability.policy_expiration_date

      result.push({
        policyType: `GL`,
        validThru: `${glStartDate} – ${glEndDate}`
      });
    }

    if (workers_compensation && workers_compensation.policy_number_string) {
      const wcStartDate = workers_compensation.policy_effective_date
      const wcEndDate = workers_compensation.policy_expiration_date
      
      result.push({
        policyType: `WC`,
        validThru: `${wcStartDate} – ${wcEndDate}`
      });
  }
  return result;

  }

  const renderReviewedCertificateRows = () => {
    function getCoverageLabels(data) {
      const policyTypes = data.map(item => item.policyType);
      return policyTypes.join(', ');
    }

    function getCoverageValidThru(data) {
      const validThruDates = data.map(item => item.validThru);
      return validThruDates.join(', ');
    }

    const rows = filteredCertificates.map((reviewedCertificate) => {
      const status = reviewedCertificate?.status
      const statusClass = classNames({
        'green': status === APPROVED,
        'red': status === DENIED
      });
      
      const parsedCetificate = JSON.parse(reviewedCertificate?.parsedCertificateJson)
      const coverageDetails = getCertificateReviewCoverageDetails(parsedCetificate)
      
      const pdfUrl = reviewedCertificate?.certificate?.pdfUrl

      return (
        <tr className="bb" key={reviewedCertificate?.publicId}>
          <td className="tl ph2 pv3 f7">{reviewedCertificate?.publicId}</td>
          <td className="tl ph2 pv3 f7 ttu">{getCoverageLabels(coverageDetails)}</td>
          <td className="tl ph2 pv3 f7 ttu">{getCoverageValidThru(coverageDetails)}</td>
          <td className={`tl ph2 pv3 f7 ttc ${statusClass}`}>{status.toLowerCase()}</td>
          <td className="tl ph2 pv3 f7 tc">
            { pdfUrl ?
            <a className="dark-gray" href={pdfUrl} target="_blank" rel="noopener noreferrer">
              <img className="dib v-btm pr1 ph3 w1" src="/document.svg" alt="certificate-of-insurance" />
            </a> : null
            }
          </td>
        </tr>
      )
    })

    return rows
  }


  const renderReviewedCertificateTable = (reviewedCertificates) => {
    return (
      <div>
        <table className="w-100 bb b--light-gray collapse ph2 mt4 mb3">
        <thead>
          <tr className="bb b--light-gray">
          <th className="tl pv3 ph2 fw5 f7 ttu w-15">Review ID</th>
            <th className="tl pv3 ph2 fw5 f7 ttu">Coverage</th>
            <th className="tl pv3 ph2 fw5 f7 ttu">Coverage Period</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15">Status</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15 tc">Certificate</th>
          </tr>
        </thead>
        <tbody>
          {renderReviewedCertificateRows(reviewedCertificates)}
        </tbody>
        </table>
      </div>
    )
  }
  
  return (
      <>
      {filteredCertificates.length > 0 &&
        <div className="mt5">
          <h3 className="dark-gray bb b--black-10 mt0 pb3">
            Reviewed Certificates
          </h3>
          <div className="gray f6 fw2 lh-copy">
            {renderReviewedCertificateTable(filteredCertificates)}
          </div>
        </div>
      }
      </>
  )
}

export default CertificatesTable