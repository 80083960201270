import { gql } from "@apollo/client";

const UPDATE_CONTRACTOR_DETAILS = gql`
  mutation UpdateContractorDetails(
    $contractor: ContractorInput
    $job: JobInput
    $entity: EntityInput
    $quote: QuoteInput
    $assignment: AssignmentInput
  ) {
    updateContractorDetails(
      contractor: $contractor
      job: $job
      entity: $entity
      quote: $quote
      assignment: $assignment
    ) {
      ok
      message
    }
  }
`;

const CANCEL_CONTRACTOR_POLICY = gql`
  mutation CancelContractorPolicy($policy: String!) {
    cancelContractorPolicy(policy: $policy) {
      ok
    }
  }
`;

const CREATE_QUOTE = gql`
  mutation CreateQuote(
    $contractor: String!
    $workState: String!
    $residenceState: String!
    $job: JobInput!
    $coverageType: String!
    $entity: EntityInput!
    $effectiveDate: DateTime!
    $endDate: DateTime!
  ) {
    createQuote(
      contractor: $contractor
      workState: $workState
      residenceState: $residenceState
      job: $job
      coverageType: $coverageType
      entity: $entity
      effectiveDate: $effectiveDate
      endDate: $endDate
    ) {
      ok
      message
      quote {
        publicId
        insuranceApplication
        job {
          jobCategory {
            className
          }
        }
        coverageType {
          edges {
            node {
              name
            }
          }
        }
      }
    }
  }
`;

const CREATE_ASSSIGNMENT = gql`
  mutation CreateAssignment(
    $contractor: String!
    $job: JobInput!
    $quote: String!
    $entity: EntityInput!
    $effectiveDate: DateTime!
    $endDate: DateTime!
    $purchaseOrderContactInfo: PurchaseOrderContactInfoInput
  ) {
    createAssignment(
      contractor: $contractor
      job: $job
      quote: $quote
      entity: $entity
      effectiveDate: $effectiveDate
      endDate: $endDate
      purchaseOrderContactInfo: $purchaseOrderContactInfo
    ) {
      ok
      message
      assignment {
        workState
        residenceState
        effectiveDate
        endDate
        totalPremiumOwed
        certificate {
          edges {
            node {
              wcCoiPdfUrl
              glCoiPdfUrl
            }
          }
        }
        job {
          publicId
          name
          description
          wage
          jobCategory {
            className
            code
          }
          entity {
            name
          }
        }
      }
    }
  }
`;

const EXPIRE_RENEW_APPLICATION = gql`
  mutation ExpireRenewInsuranceApplication($insuranceApplicationUrl: String!) {
    expireRenewInsuranceApplication(
      insuranceApplicationUrl: $insuranceApplicationUrl
    ) {
      ok
      message
      url
      quoteId
    }
  }
`;

const MARK_OFAC_USER_EXCEPTION = gql`
  mutation MarkOfacUserException($insuranceApplicationUrl: String!) {
    markOfacUserException(insuranceApplicationUrl: $insuranceApplicationUrl) {
      ok
      message
      quoteId
    }
  }
`;

const CANCEL_ASSIGNMENT = gql`
  mutation CancelAssignment(
    $assignment: String!
    $sendEmail: Boolean!
    $cancellationDate: Date!
  ) {
    cancelAssignment(
      assignment: $assignment
      sendEmail: $sendEmail
      cancellationDate: $cancellationDate
    ) {
      ok
      message
    }
  }
`;

const ADD_PURCHASE_ORDER_NUMBER = gql`
  mutation AddPurchaseOrderNumber($purchaseOrder: [PurchaseOrderInput!]) {
    addPurchaseOrderNumber(purchaseOrder: $purchaseOrder) {
      ok
      message
    }
  }
`;

const DELETE_CONTRACTOR = gql`
  mutation DeleteContractor($contractor: String!) {
    deleteContractor(contractor: $contractor) {
      ok
    }
  }
`;

const SEND_INVITE = gql`
  mutation SendInsuranceApplicationInvite(
    $subject: String!
    $messageBody: String!
    $quoteId: String!
  ) {
    sendInsuranceApplicationInvite(
      subject: $subject
      messageBody: $messageBody
      quoteId: $quoteId
    ) {
      ok
    }
  }
`;

const UPDATE_ASSIGNMENT_WAGE = gql`
  mutation UpdateAssignmentWage(
    $jobId: String!
    $contractorId: String!
    $newWage: Int!
  ) {
    updateAssignmentWage(
      jobId: $jobId
      contractorId: $contractorId
      newWage: $newWage
    ) {
      ok
      message
    }
  }
`;

const UPDATE_ASSIGNMENT_DETAILS = gql`
  mutation UpdateAssignmentDetails(
    $quoteId: ID
    $assignmentId: ID
    $jobId: ID
    $jobName: String
    $entityName: String
    $clientName: String
    $effectiveDate: Date
    $endDate: Date
  ) {
    updateAssignmentDetails(
      quoteId: $quoteId
      assignmentId: $assignmentId
      jobId: $jobId
      jobName: $jobName
      entityName: $entityName
      clientName: $clientName
      effectiveDate: $effectiveDate
      endDate: $endDate
    ) {
      ok
      message
    }
  }
`;

export {
  UPDATE_CONTRACTOR_DETAILS,
  CANCEL_CONTRACTOR_POLICY,
  CANCEL_ASSIGNMENT,
  CREATE_QUOTE,
  CREATE_ASSSIGNMENT,
  EXPIRE_RENEW_APPLICATION,
  MARK_OFAC_USER_EXCEPTION,
  ADD_PURCHASE_ORDER_NUMBER,
  SEND_INVITE,
  DELETE_CONTRACTOR,
  UPDATE_ASSIGNMENT_WAGE,
  UPDATE_ASSIGNMENT_DETAILS,
};
