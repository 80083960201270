import React, { useState, useCallback } from "react";

import ReactLoading from "react-loading";
import Modal from "react-modal";

import { useMutation } from "@apollo/client";

import moment from "moment";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import PageHeader from "../../components/PageHeader";
import {
  CANCEL_ASSIGNMENT,
} from "./mutations";

let contentStyle = {
  width: "40%",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )",
};

let modalStyles = {
  content: contentStyle,
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

function CancelAssignmentModal(props) {
  const [sendEmail, setSendEmail] = useState(false);
  const [cancellationDate, setCancellationDate] = useState(new Date());

  const [cancelAssignment, { loading, error, reset }] = useMutation(
    CANCEL_ASSIGNMENT,
    {
      onCompleted: (data) => {
        if (data?.cancelAssignment?.ok) {
          props.closeModal();
          props.setSuccessMessage(
            `The assignment with the id matching ${props.assignment} was successfully cancelled.`
          );
          reset();
        } else {
          props.setErrorMessage(data?.cancelAssignment?.message);
        }
      },
      onError: (error) => {
        console.log(error);
        props.setErrorMessage(error?.message);
        reset();
      },
      refetchQueries: ["Contractor", "ContractorByID"],
    }
  );

  const handleCancelAssignment = useCallback(() => {
    cancelAssignment({
      variables: {
        assignment: props.assignment,
        sendEmail: sendEmail,
        cancellationDate: moment(cancellationDate).format("YYYY-MM-DD"),
      },
    });
  }, [cancelAssignment, props.assignment, sendEmail, cancellationDate]);

  const renderLoading = () => (
    <ReactLoading type={"spin"} color={"#cccccc"} className="center" />
  );

  const renderError = (errorMsg) => (
    <div className="br2 f6 flex items-center justify-center pa2 bg-washed-red red">
      <span className="lh-title">
        {" "}
        {errorMsg ??
          "There was an error cancelling the assignment. Please try again."}
      </span>
    </div>
  );

  const renderCancelConfirmation = (props) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const formatDateForInput = (date) => moment(date).format("MM/DD/YYYY");
    const parseDateFromInput = (str) => moment(str, "MM/DD/YYYY").toDate();

    return (
      <div
        className="roboto flex flex-column"
        style={{
          height: "70vh",
          maxHeight: "900px",
          minHeight: "520px",
          overflow: "auto",
        }}
      >
        <div className="flex-grow-1 overflow-auto">
          <PageHeader title={"Confirm Assignment Cancellation"} />
          {props.modalErrorMessage && renderError(props.modalErrorMessage)}
          <div className="f6 fw5 db mb2 mt3 lh-copy">
            Are you sure you want to cancel the Assignment with the following id{" "}
            <b className="red">{props.assignment}</b>? This operation can't be
            undone.
          </div>
          <div className="mv4">
            <label className="f6 db mb2">Cancellation Date:</label>
            <DayPickerInput
              value={cancellationDate}
              onDayChange={(day) => setCancellationDate(day)}
              formatDate={formatDateForInput}
              parseDate={parseDateFromInput}
              format="MM/DD/YYYY"
              placeholder={`${formatDateForInput(new Date())}`}
              dayPickerProps={{
                disabledDays: { after: today },
                modifiers: {
                  disabled: { after: today },
                },
                toMonth: today,
              }}
              inputProps={{
                className: "input-reset ba b--black-20 br2 w-100",
              }}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="flex flex-column pa3 bt b--light-gray">
          <div className="mb3 flex justify-end">
            <label className="f6 flex items-center">
              <input
                type="checkbox"
                checked={sendEmail}
                onChange={(e) => setSendEmail(e.target.checked)}
                className="mr2"
              />
              Send cancellation email
            </label>
          </div>
          <div className="flex justify-end">
            <button
              onClick={props.closeModal}
              className="f6 link dim br2 ba ph3 pv2 dib black pointer mr2"
              type="button"
            >
              Cancel
            </button>
            <button
              className="f6 link dim br2 ph3 pv2 dib red ba bg-washed-red b--red pointer"
              type="submit"
              onClick={handleCancelAssignment}
            >
              YES – CANCEL the assignment!
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel="Cancel assignment coverage."
    >
      {loading ? renderLoading() : renderCancelConfirmation(props)}
    </Modal>
  );
}

export default CancelAssignmentModal;
